import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { RiskRegionSubjectivityService } from 'src/app/services/risk-region-subjectivity.service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { convertDate, getFormattedPeriod } from 'src/app/utils/formatDate';

@Component({
  selector: 'app-modal-subjectivities',
  templateUrl: './modal-subjectivities.component.html',
  styleUrls: ['./modal-subjectivities.component.less'],
})
export class ModalSubjectivitiesComponent implements OnInit {
  form: FormGroup;
  @Input() showModal: boolean = false;
  @Input() riskRegionId;
  @Input() product;
  @Input() selectedSubjectivities;

  @Output() handleClose = new EventEmitter<any>();
  @Output() addHandler = new EventEmitter<any>();

  subjectivityOptns = [];
  currentPage = 1;
  totalPages = 1;
  totalCount = 1;
  subjectivitiesSelectedToAdd = [];
  subjectivitiesCreatedToAdd = [];
  remainingCount = 0;
  editedSubjectivity: string = '';
  initialEditedSubjectivity = '';
  editedCustomSubjectivityIndex = null;
  addBtnClicked = false;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private riskRegionSubjectivityService: RiskRegionSubjectivityService,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      filterKeyWord: [''],
    });
    this.handleSubjectivitySearch('', this.product);
    this.remainingCount =
      10 - this.selectedSubjectivities[this.product]?.length;
  }

  handleModalClick(event) {
    event.stopPropagation();
  }

  handleBackDropClick() {
    this.showModal = false;
    this.handleClose.emit();
  }

  async onSearch(event) {
    const keyword = event.target.value;
    if (keyword?.length >= 3 && keyword?.length <= 500) {
      await this.handleSubjectivitySearch(keyword, this.product, 1, false, 10);
    } else {
      this.handleSubjectivitySearch('', this.product, 1, false, 10);
    }
  }

  handleSubjectivitySearch(
    searchKey,
    product,
    page = 1,
    isDefaultLoad = true,
    limit = 10,
  ) {
    this.riskRegionSubjectivityService
      .getSubjectivity(this.riskRegionId, searchKey, 1, page, limit)
      .subscribe((data) => {
        this.currentPage = data.pagination.currentPage;
        this.totalPages = data.pagination.totalPages;
        this.totalCount = data.pagination.totalRecords;
        const newList = data.data.map((sub) => {
          let selected = this.selectedSubjectivities[product].find(
            (x) =>
              x.riskRegionSubjectivitiesId &&
              x.riskRegionSubjectivitiesId === sub.id &&
              x.key === sub.description,
          )
            ? true
            : false;

          return {
            key: sub.description,
            value: sub.description,
            disabled: selected ? true : false,
            mandatory: sub.mandatory,
            riskRegionSubjectivitiesId: sub.id,
            isCompliedWith: !!sub.isCompliedWith,
            documentTblSubId: null,
            edit: false,
          };
        });
        if (isDefaultLoad && this.subjectivityOptns[product]?.length > 0) {
          this.subjectivityOptns[product] = [
            ...this.subjectivityOptns[product],
            ...newList,
          ];
        } else {
          this.subjectivityOptns[product] = newList;
        }
      });
  }

  clearValue() {
    this.form.reset();
    this.handleSubjectivitySearch('', this.product, 1, false, 10);
  }

  getFilteredResult(keyName) {
    return keyName?.replace(
      this.form.value['filterKeyWord'],
      `<strong>${this.form.value['filterKeyWord']}</strong>`,
    );
  }

  handleSubjectivitySelect(sub) {
    const alreadySelectedIdx = this.subjectivitiesSelectedToAdd.findIndex(
      (selectedSubjectivity) => selectedSubjectivity.key === sub.key,
    );
    if (alreadySelectedIdx !== -1) {
      this.subjectivitiesSelectedToAdd.splice(alreadySelectedIdx, 1);
    } else {
      if (this.maxCountReached()) {
        return;
      }
      this.subjectivitiesSelectedToAdd.push({
        ...sub,
        selectedToAdd: true,
      });
    }
  }

  isSelected(sub) {
    return this.subjectivitiesSelectedToAdd.filter(
      (selectedSub) => selectedSub.key === sub.key,
    )[0]?.selectedToAdd;
  }

  onScroll(event: any): void {
    const currentScrollPosition = event.target.scrollTop;
    const maxScrollPosition =
      event.target.scrollHeight - event.target.clientHeight;

    if (this.canLoadMoreOptions(currentScrollPosition, maxScrollPosition)) {
      this.currentPage++;
      this.handleSubjectivitySearch(
        this.form.value['filterKeyWord'],
        this.product,
        this.currentPage,
      );
    }
  }

  canLoadMoreOptions(
    currentScrollPosition: number,
    maxScrollPosition: number,
    threshold: number = 4,
  ): boolean {
    return (
      maxScrollPosition - currentScrollPosition <= threshold &&
      this.subjectivityOptns[this.product].length <= this.totalCount &&
      this.currentPage <= this.totalPages
    );
  }

  addClickHandler() {
    this.addBtnClicked = true;
    const selectedSubjectivities = this.subjectivitiesSelectedToAdd.map(
      ({ selectedToAdd, ...rest }) => rest,
    );
    this.addHandler.emit({
      subjectivitiesToAdd: selectedSubjectivities,
      product: this.product,
      subjectivitiesCreatedToAdd: this.subjectivitiesCreatedToAdd,
    });
  }

  createSubjectivity() {
    const value = this.form.value['filterKeyWord'];
    const index = this.subjectivitiesCreatedToAdd.indexOf(value);
    if (index === -1) {
      this.subjectivitiesCreatedToAdd.push(this.form.value['filterKeyWord']);
    }
  }

  removeCreatedSubjectivity(sub) {
    const index = this.subjectivitiesCreatedToAdd.indexOf(sub);
    if (index !== -1) {
      this.subjectivitiesCreatedToAdd.splice(index, 1);
    }
  }

  maxCountReached() {
    const totalSelectedCount =
      this.subjectivitiesCreatedToAdd?.length +
      this.subjectivitiesSelectedToAdd.length;
    if (totalSelectedCount >= this.remainingCount) {
      return true;
    }
    return false;
  }

  editSubjectivity(subjectivityIdx) {
    if (this.maxCountReached()) {
      return;
    }
    const subjectivityData =
      this.subjectivityOptns[this.product][subjectivityIdx];
    if (subjectivityData.disabled) {
      return;
    }
    this.subjectivityOptns[this.product] = this.subjectivityOptns[
      this.product
    ].map((sub, index) => {
      if (index === subjectivityIdx) {
        this.editedSubjectivity = sub.key;
        this.initialEditedSubjectivity = this.editedSubjectivity;
        return {
          ...sub,
          edit: true,
        };
      } else {
        return {
          ...sub,
          edit: false,
        };
      }
    });
  }

  confirmEditSubjectivity(subjectivityIdx) {
    if (
      this.initialEditedSubjectivity.trim() !== this.editedSubjectivity.trim()
    ) {
      const value = this.editedSubjectivity.trim();
      const index = this.subjectivitiesCreatedToAdd.indexOf(value);
      if (index === -1) {
        this.subjectivitiesCreatedToAdd.push(value);
        const sub = this.subjectivityOptns[this.product][subjectivityIdx];
        const alreadySelectedIdx = this.subjectivitiesSelectedToAdd.findIndex(
          (selectedSubjectivity) => selectedSubjectivity.key === sub.key,
        );
        if (alreadySelectedIdx !== -1) {
          this.subjectivitiesSelectedToAdd.splice(alreadySelectedIdx, 1);
        }
        this.cancelEdit();
      }
    }
  }

  cancelEdit() {
    this.initialEditedSubjectivity = '';
    this.editedSubjectivity = '';
    this.editedCustomSubjectivityIndex = null;
    this.subjectivityOptns[this.product] = this.subjectivityOptns[
      this.product
    ].map((sub, index) => {
      return {
        ...sub,
        edit: false,
      };
    });
  }

  editCustomSubjectivity(index) {
    if (this.subjectivitiesSelectedToAdd.length >= this.remainingCount) {
      return;
    }
    this.cancelEdit();
    const subjectivityData = this.subjectivitiesCreatedToAdd[index];
    this.editedSubjectivity = subjectivityData;
    this.initialEditedSubjectivity = subjectivityData;
    this.editedCustomSubjectivityIndex = index;
  }

  confirmEditCustomSubjectivity(i) {
    if (
      this.initialEditedSubjectivity.trim() !== this.editedSubjectivity.trim()
    ) {
      this.subjectivitiesCreatedToAdd[i] = this.editedSubjectivity;
      this.cancelEdit();
    }
  }

  isConfirmDisabled() {
    if (
      this.initialEditedSubjectivity.trim() == this.editedSubjectivity.trim()
    ) {
      return true;
    }
    const isPresentInCustom =
      this.subjectivitiesCreatedToAdd.findIndex(
        (sub) => sub.trim() === this.editedSubjectivity.trim(),
      ) !== -1;
    if (isPresentInCustom) {
      return true;
    }
    const isAlreadyInSuggestedList =
      this.subjectivityOptns[this.product].filter(
        (sub) => sub.key.trim() === this.editedSubjectivity.trim(),
      ).length > 0;
    if (isAlreadyInSuggestedList) {
      return true;
    }
    return false;
  }
}
