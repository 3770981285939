import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, retry, catchError } from 'rxjs';
import { BoxxResponse } from '../entities/boxx-response';
import { BaseService } from './base-services';

@Injectable({
  providedIn: 'root',
})
export class RiskRegionSubjectivityService extends BaseService<any> {
  constructor(http: HttpClient) {
    const baseurl =
      environment.msProductriskBaseUrl + environment.riskRegionSubjectivity;
    super(http, baseurl);
  }

  getSubjectivity(
    riskRegionId: number,
    description: string = '',
    active: number | string = '',
    page: number = 1,
    limit = 100,
  ): Observable<any> {
    let url = `${this.baseurl}?riskRegionId=${riskRegionId}&description=${description}&page=${page}&limit=${limit}`;
    if (active) {
      url += `&active=${active}`;
    }
    return this.http
      .get<BoxxResponse<any>>(url)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
