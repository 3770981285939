import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { InsuredService } from 'src/app/services/insured.service';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { getErrorMessage } from 'src/app/utils/utils';
import { DashboardHomeComponent } from 'src/app/dashboard/dashboard-home/dashboard-home.component';
import { QuotesGridV2Component } from '../quotes-grid-v2/quotes-grid-v2.component';
import { PolicyDirectoryComponent } from '../../policy-directory/policy-directory.component';
import { InsuredFormWorkflow3Component } from '../../insured-form-workflow3/insured-form-workflow3.component';
import { environment } from 'src/environments/environment';
import { BranchProfileComponent } from '../../brokerage-module/branch-profile/branch-profile.component';
import { TranslateService } from '@ngx-translate/core';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { Store, select } from '@ngrx/store';
@Component({
  selector: 'app-navigation-v2',
  templateUrl: './navigation-v2.component.html',
  styleUrls: ['./navigation-v2.component.less'],
})
export class NavigationV2Component implements OnInit {
  //breadcrumbs and search
  @Input() showHeader: boolean = false;
  @Input() items: Array<any> = [];
  @Input() containerStyle: { [klass: string]: any } = {};
  @Input() bannerStyle: { [klass: string]: any } = {};
  @Input() isRightIcon: boolean = true;
  @Input() rightIcon: string = 'assets/img/workflow3/search.svg';
  @Input() id: string;
  @Input() label: string;
  @Input() placeholder: string = 'Search';
  @Input() showError: boolean;
  @Input() isRequired: boolean;
  @Input() control: string;
  @Input() type: string = 'text';
  @Input() inputListHdr: string = '';
  @Input() dropdownLabel: string = 'Policy #';

  @Input() isNeeded: boolean = false;
  @Output() linkClicked = new EventEmitter();
  @Output() handleKeyUp = new EventEmitter();
  @Output() suggestedSearchSelected = new EventEmitter();

  showClose: boolean = false;
  selectedSearch: string;
  searchKey: string;
  showErrorAlert = false;
  msgErrorAlert = '';
  isRestrictedRoleSubmission: boolean = null;

  //Banner
  @Input() btnRightImage: string = 'assets/img/workflow3/plus-circle.svg';
  @Input() btnLabel: string = 'Start new quote';
  @Input() btnClass: string = 'primary';
  @Input() mainHeading: string = 'Policy Directory';
  @Input() subHeading: string = 'Keep track of the policies and their status.';
  @Input() badgeData: string = '20 Records';
  @Input() showHeaderBanner: boolean = false;

  @Output() buttonClicked = new EventEmitter();

  inputOptions: any;
  dropdownOptions: any = [
    { key: 'Policy #', value: 'Policy number', id: 'Policy number' },
    { key: 'Insured name', value: 'Insured name', id: 'Insured name' },
  ];
  mockExternalAPIs = 'true';
  showBodySpinner: boolean = false;
  searchIn: string = this.dropdownOptions[0].value;

  public searchListOpen: boolean = false;
  public get dropdownSearchElement(): Element {
    return this.elem.nativeElement.querySelector('.search-list');
  }
  private currentSearchListIndex = -1;

  constructor(
    private store: Store,
    private elem: ElementRef,
    public policyService: PolicyDashboardService,
    public insuredService: InsuredService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
  ) {}
  ngOnInit(): void {
    if (
      this.localStorageService.getMockExternalAPIs() === 'false' ||
      environment.mockExternalAPIs === false
    ) {
      this.mockExternalAPIs = 'false';
    } else {
      this.mockExternalAPIs = 'true';
    }
    this.btnLabel = this.translateService.instant('common.startNewQuote');
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.isRestrictedRoleSubmission = data.isRestrictedRoleSubmission;
    });
    this.updateHeaderData();
    if (this.isRestrictedRoleSubmission === true) {
      this.btnLabel = this.translateService.instant(
        'common.startNewSubmission',
      );
    }
    if (
      this.items?.length > 0 &&
      !this.items.some((a) => a?.activeStep === true)
    ) {
      this.items[this.items.length - 1].activeStep = true;
    }
  }
  getIconSrc() {
    return this.rightIcon;
  }
  emitClickEvent(event: any) {
    this.linkClicked.emit();
  }
  handleButtonClickHandler(event) {
    this.buttonClicked.emit();
  }

  async onKeyUp(event) {
    // TODO - API call to get search suggestions.
    if (this.searchIn === this.dropdownOptions[0].value) {
      if (this.searchKey.length >= 1) {
        const pkgPolicyNumber = encodeURIComponent(this.searchKey);
        const page = 1;
        const limit = 5;
        const sort = '';

        await this.policyService
          .GetAllByPolicyNumber(pkgPolicyNumber, page, limit, sort)
          .subscribe({
            next: (response) => {
              const tableData = response.data?.map((dataObj) => ({
                name: dataObj.policy.pkgPolicyNumber,
                value: dataObj.policy.pkgPolicyNumber,
              }));
              this.inputOptions = tableData;
            },
            error: (error) => {
              if (![500].includes(error?.status)) {
                this.showErrorAlert = true;
              }
              this.msgErrorAlert = getErrorMessage(error);
            },
          });

        // TODO - no need to show suggestion list if no matches found
        this.showClose = true;
        this.searchListOpen = true;
        this.dropdownSearchElement.setAttribute('aria-expanded', 'true');
      } else {
        this.closeSearchDropdown();
      }
    } else {
      if (this.searchKey.length >= 3) {
        const page = 1;
        const limit = 5;
        const sort = '';
        await this.policyService
          .GetAllByInsuredName(
            encodeURIComponent('%' + this.searchKey),
            page,
            limit,
            sort,
          )
          .subscribe({
            next: (response) => {
              const tableData = response.data?.map((dataObj) => ({
                name: this.makeSuggest(dataObj.policy.insured),
                value: this.makeSuggest(dataObj.policy.insured),
                id: dataObj.policy?.insured?.id,
                type:
                  dataObj.policy.insured.insuredType === 2
                    ? 'company'
                    : 'individual',
              }));

              this.inputOptions = tableData.filter(
                (obj, index, self) =>
                  index === self.findIndex((o) => o.id === obj.id),
              );
            },
            error: (error) => {
              if (![500].includes(error?.status)) {
                this.showErrorAlert = true;
              }
              this.msgErrorAlert = getErrorMessage(error);
            },
          });

        // TODO - no need to show suggestion list if no matches found
        this.showClose = true;
        this.searchListOpen = true;
        this.dropdownSearchElement.setAttribute('aria-expanded', 'true');
      } else {
        this.closeSearchDropdown();
      }
    }

    this.handleKeyUp.emit(event);
  }

  closeSearchDropdown() {
    this.searchListOpen = false;
    this.dropdownSearchElement.setAttribute('aria-expanded', 'false');
  }
  makeSuggest(insured) {
    if (insured.insuredType === 2) return insured.companyName;
    return insured.firstName + ' ' + insured.lastName;
  }
  selectSuggested(selectedObj) {
    this.selectedSearch =
      this.inputOptions[
        this.inputOptions.findIndex((obj) => obj.value == selectedObj.value)
      ];
    this.searchKey = selectedObj.value;
    this.closeSearchDropdown();
    this.suggestedSearchSelected.emit({
      selectedSearch: this.selectedSearch,
      searchIn: this.searchIn,
      type: selectedObj.type,
    });
  }
  getSuggestedValue(value) {
    let strArr = [];
    const index =
      value &&
      this.searchKey &&
      value.toLowerCase().indexOf(this.searchKey.toLowerCase());
    if (index !== -1) {
      strArr[0] = value.substring(0, index);
      strArr[1] = value.substring(
        index + (this.searchKey && this.searchKey.length),
      );
    } else {
      strArr[0] = value;
    }
    return strArr;
  }
  hasSuggestedResults(value) {
    const index =
      value &&
      this.searchKey &&
      value.toLowerCase().indexOf(this.searchKey.toLowerCase());
    return index !== -1;
  }
  clearSearchValue() {
    this.searchKey = '';
  }

  updateHeaderData() {
    // this.showHeader = false;
    const routeComponent = this.route.firstChild?.component;
    if (
      routeComponent === DashboardHomeComponent ||
      routeComponent === QuotesGridV2Component
    ) {
      this.showHeader = true;
      this.showHeaderBanner = false;
    } else if (routeComponent === PolicyDirectoryComponent) {
      this.showHeader = true;
      this.showHeaderBanner = true;
    }
    if (routeComponent === InsuredFormWorkflow3Component) {
      this.showHeader = true;
      this.showHeaderBanner = true;
    }
    if (
      this.route?.routeConfig?.component?.name ===
      'InsuredFormWorkflow3Component'
    ) {
      this.showHeader = true;
      this.showHeaderBanner = true;
    }
    if (routeComponent === BranchProfileComponent) {
      this.showHeader = true;
      this.showHeaderBanner = true;
    }
  }
}
